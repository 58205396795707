@use 'fonts'
@import '~include-media/dist/_include-media.scss'
@import 'colours'
@import 'shadows'

#related-container
    background-color: $lightgrey

    #multi-related-articles
        background-color: $lightgrey
        padding-top: 1rem

        h1
            padding: 1.5rem
            font-size: 4rem
            font-weight: 800
            color: $dark-blue

        h2
            margin-left: 1.5rem
            margin-right: 1.5rem
            font-weight: 800
            color: $deepspace-blue
            margin-top: 1rem
            margin-bottom: 1.5rem

        .related-carousel
            max-width: 1000px

        .control-button
            font-size: 5rem
            display: flex
            align-items: center
            vertical-align: middle

        .control-left
            margin-right: 0
            padding-right: 0

        .control-right
            margin-left: 0
            padding-left: 0

        .controls
            margin: 0
            margin-top: 10%
            padding: 0
            height: 100%
            width: 100%
            width: 1rem
            // color: red
            z-index: 100000
            text-align: center
            font-size: 3rem
            display: block

        .card
            background-color: $lightgrey !important
            border: none
            font-size: 0.9rem
            color: $deepspace-blue

            .card-body
                padding: 0.5rem
                padding-top: 1.5rem
                padding-bottom: 1rem

            .card-title
                font-size: 1.5rem
                font-weight: 800
                // text-transform: capitalize

            
            &:hover
                background-color: $esa-teal !important

        // .tint
        //     position: relative
        //     float: left
        //     cursor: pointer
            
        //     &:hover:before
        //         content: ""
        //         display: block
        //         position: absolute
        //         top: 0
        //         bottom: 0
        //         left: 0
        //         right: 0
        //         background: rgba($esa-teal, 0.5)
        //         transition: background .3s linear

    .read-more
        margin: 2rem
