@use 'fonts'
@import 'colours'
@import 'shadows'

#main-error
    color: $deepspace-blue
    padding-left: 5rem
    padding-top: 3rem
    background-color: #fff
    font-size: 1.5rem

    h1
        color: $esa-teal
        margin-top: 150px
        font-weight: 800
        font-size: 8rem

    h2, h3, h4
        margin-top: 10rem

    p
        color: $deepspace-blue
        font-size: 2 rem
        line-height: 1.1
        margin-top: 2rem

    ul
        font-weight: 100
        font-size: 2rem
        margin-bottom: 5rem

    li
        font-size: 2rem

    .message
        font-weight: 800
        font-size: 4rem

    .error-content
        max-width: 1250px

    .errorcode
        font-weight: 800
        font-size: 3rem
                

    .related
        max-width: 150px
        @media screen and (min-width: 768px)
            margin-left: 3rem
            padding-bottom: 1.5rem
        else
            margin-left: 1.5rem
            padding-bottom: 3rem

    #error-icon
        margin-top: 250px
        text-align: left
